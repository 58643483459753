<template>
  <div id="customer-detail">
    <c-nav-bar title="客户账本详情"></c-nav-bar>
    <div class="content">
      <div class="panel">
        <div class="l">
          <img :src="data.user.avatar_url" />
        </div>
        <div class="r">
          <div class="t">
            {{ originRemark }}
            <van-icon class="ml10" @click="handleShowRemark()" name="edit" />
          </div>
          <div class="b">余额：{{ data.balance | formatMoneyValue }}</div>
        </div>
      </div>
      <van-cell-group>
        <van-cell
          title="账本统计"
          is-link
          @click="handleGoPage('customer-summary')"
        ></van-cell>
        <van-cell
          title="账本明细"
          is-link
          @click="handleGoPage('customer-record')"
        ></van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import CNavBar from "@/components/CNavBar.vue";

import customer from "@/api/customer";

export default {
  name: "customer-detail",
  data() {
    return {
      pk: null,
      originRemark: "",
      data: {
        user: {
          avatar_url: "",
          nickname: "",
        },
        balance: 0,
      },
    };
  },
  created() {
    this.handleGetCustomer();
  },
  components: {
    CNavBar,
  },
  methods: {
    handleGetCustomer() {
      customer
        .get(this.$route.query.pk)
        .then((res) => {
          this.data = res.results;
          this.pk = res.results.pk;
          this.originRemark = this.data.user.remark
            ? this.data.user.remark
            : this.data.user.nickname;
          this.remark = this.originRemark;
        })
        .catch((err) => {
          this.$toast({
            message: err.message,
          });
        });
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    handleGoPage(name) {
      this.$router.push({
        name: name,
        query: { pk: this.pk },
      });
    },
  },
};
</script>

<style lang="stylus">
#customer-detail {
  .content {
    position: fixed;
    left: 0;
    right: 0;
    top: 56px;
    bottom: 0;

    .panel {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 20px;
      background: #ffffff;

      img {
        width: 45px;
        height: 45px;
        margin-right: 10px;
        border-radius: 50%;
      }

      .t {
        color: #333333;
        font-size: 16px;
        display flex
        align-items center
      }

      .b {
        color: #666666;
        font-size: 12px;
      }
    }
  }
}
</style>
